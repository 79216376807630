.s-full-ticket {
  padding: 100px 0 60px;

  @include md {
    padding: 80px 0 60px; }

  @include sm {
    padding: 40px 0 40px; }

  .container {
    max-width: 1260px; }

  &__landing-links {
    margin-top: 50px; } }

.full-ticket {
  display: flex;
  margin: 70px 0 0;
  gap: 20px;
  flex-wrap: wrap;

  @include md {
    margin: 50px -10px 0;
    flex-wrap: wrap;
    gap: 10px; }

  @include sm {
    margin: 50px -10px 0;
    flex-wrap: wrap; }

  &__item {
    position: relative;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.28;
    padding: 34px 20px;
    min-height: 250px;
    color: #fff;
    background: $mainBg;
    border: 5px solid $gray2;

    &.full-width {
      min-width: 100%;
      flex-direction: row;

      @include sm {
        flex-direction: column; }

      .full-ticket {

        &__left {
          display: flex;
          flex-direction: column;
          width: 418px;
          margin-right: 50px;
          flex: 1 0 auto;
          @include md {
            width: 300px; }

          @include sm {
            width: auto;
            margin-right: 0; } }

        &__title {
          margin: 0; }

        &__img-wrap {
          margin: 24px 0 58px; }

        &__desc {
          margin: 0;
          flex-direction: column;
          text-align: left;
          justify-content: center;
          align-items: flex-start;

          p {
            &:not(:first-child) {
              margin: 8px 0 0; } }

          p {
            &:nth-child(2) {
              margin-top: 30px; } } } } }

    @include md {
      padding: 20px 20px 20px;
      min-height: 250px;
      min-width: calc(50% - 10px); }

    @include sm {
      margin: 0 auto;
      flex: 1 1 calc(50% - 10px);
      min-height: 250px; }

    @include xxs {
      flex: 1 1 100%;
      min-height: 250px; }

    &:hover {
      .full-ticket {
        &__bg {
          opacity: 1; } } } }

  a.full-ticket__item {
    text-decoration: underline;

    &:hover {
      text-decoration: none; } }

  &__bg {
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    background-position: center center;
    background-size: cover;
    opacity: 0;
    filter: brightness(0.7);
    transition: all ease 0.1s; }

  &__title {
    position: relative;
    font-size: clamp(24px, 4vw, 34px);
    text-transform: uppercase;
    font-weight: 900;
    line-height: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 0 12px;
    min-height: 92px;
    z-index: 1;
    order: 1; }

  &__img-wrap {
    position: relative;
    width: 100%;
    height: 60px;
    margin: 60px 0 96px;
    z-index: 1;
    order: 0; }

  &__img {
    display: block;
    height: 100%;
    width: auto;
    margin: 0 auto; }

  &__desc {
    position: relative;
    display: flex;
    font-size: 14px;
    line-height: 1.42;
    align-items: center;
    justify-content: center;
    margin: auto 0 0;
    text-align: center;
    min-height: 62px;
    z-index: 1;
    order: 1;

    @include md {
      min-height: 80px; }

    @include sm {
      min-height: 60px; } } }

.landing-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 40px;
  @include sm {
    grid-template-columns: 1fr; }
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2; }

  &__list {
    list-style-type: disc;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    padding-left: 18px;
    line-height: 1.2; }
  &__link {
    text-decoration: underline;
    color: #fff;
    font-size: 14px;
    &:hover {
      text-decoration: none; } } }

.fleet {
	padding: clamp(50px, 5vw, 100px) 0;
	&__links {
		@include clr; }
	&__link {
		display: block;
		float: left;
		width: 100%;
		@include sm {
			//width: 33%
			width: 100%; }
		&--sm {
			width: 50%;
			.fleet-link {
				padding-top: 50%;

				&__title {
					font-size: 30px; }
				@include sm {
					padding: 0 0 12px; } } }
		&:focus-visible {
			outline-width: 5px;
			.fleet-link {
				&__img {
					transform: scale(1.05); }
				&:after {
					opacity: 0.25; } } } }
	&__tabs-container {
		overflow: hidden;
		position: relative;
		@include sm {
			display: flex;
			flex-direction: column;
			margin: 0 -20px; } }
	&__tab {
		display: none;
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		@include sm {
			position: unset; }

		&.is-active {
			display: block;
			@include sm {
				display: flex;
				width: 100%; } } } }


.fleet-link {
	color: $white;
	display: block;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	padding-top: 36%;
	position: relative;
	z-index: 1;
	text-decoration: none;
	cursor: pointer;
	overflow: hidden;
	&.is-scroll {
		.fleet-link__scroll {
			display: block; } }
	@include sm {
		padding: 0 0 12px;
		min-height: 211px;
		display: flex;
		align-items: flex-end; }
	//+xs
	//adding-top: 60%
	.no-touch &:hover:after {
		opacity: 0.25; }
	&:hover {
		.fleet-link__img {
			transform: scale(1.05); } }

	&:after {
		transition: 0.3s;
		content: '';
		@include coverdiv;
		background-color: #000;
		opacity: 0.5;
		z-index: -1; }
	&__img {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		transform: scale(1);
		z-index: -2;
		background-position: center center;
		background-size: cover;
		transition: all ease 0.2s; }
	&__inner {
		padding: 50px 50px 50px 40px;
		@include coverdiv;
		z-index: 1;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		@include laptop {
			padding: 30px; }
		@include sm {
			padding: 10px 21px 16px 20px; } }
	&__icon {
		height: 44px;
		min-width: 44px;
		width: 44px;
		margin-left: 20px;
		@include sm {
			height: 42px;
			min-width: 42px;
			width: 42px;
			margin-left: 10px;
			margin-bottom: 6px; }
		//+xs
		//isplay: none
		img {
			width: 100%;
			height: 100%; } }
	&__content {
		width: 100%;
		height: 100%;
		display: flex;
		max-width: 600px;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center; }
	&__title {
		@include bold;
		display: block;
		text-align: center;
		text-transform: uppercase;
		font-size: 54px;
		letter-spacing: 0.6px;

		@include laptop {
			font-size: 48px;
			margin-bottom: 20px; }
		@include tablet {
			font-size: 42px;
			margin-bottom: 15px; }
		@include md {
			font-size: 34px;
			margin-bottom: 0; } }
	&__desc {
		display: block;
		font-size: 16px;
		line-height: 1.4;
		@include tablet {
			font-size: 14px; }
		@include md {
			display: none; } }
	&__scroll {
		position: absolute;
		right: 0;
		top: 50px;
		font-size: 32px;
		-webkit-animation: scroll 1s ease-in-out infinite;
		-o-animation: scroll 1s ease-in-out infinite;
		animation: scroll 1s ease-in-out infinite;
		display: none;
		.icon {
			fill: #00dd3b; } } }

.fleet-tab {
	position: relative;
	display: flex;
	height: 100%;
	background: #0E0F0E;
	width: 100%;
	@include sm {
		height: 100%;
		flex-direction: column; }
	&__gallery {
		width: 65%;
		@include sm {
			order: 2;
			width: 100%; } }
	&__info {
		width: 35%;
		background-color: #111312;
		padding: 103px 67px 20px;
		@include laptop {
			padding: 40px 20px 20px; }
		@include md {
			padding: 34px 20px; }
		@include sm {
			width: 100%; } }
	&__title {
		font-size: 34px;
		margin-bottom: 62px;
		line-height: 1.4;
		@include tablet {
			font-size: 26px;
			margin-bottom: 20px; }
		@include md {
			font-size: 22px;
			margin-bottom: 27px;
			line-height: 1.2; } }
	&__desc {
		letter-spacing: 0.5px;
		max-width: 400px;
		line-height: 2.1;
		margin-bottom: 50px;
		@include tablet {
			line-height: 1.4;
			margin-bottom: 30px; }
		@include md {
			margin-bottom: 29px;
			font-size: 16px;
			line-height: 1.7; } }
	&__close {
		position: absolute;
		top: 30px;
		right: 30px;
		height: 17px;
		width: 17px;
		background: transparent;
		cursor: pointer;
		@include md {
			top: 18px;
			right: 18px; }
		&:before, &:after {
			position: absolute;
			top: 50%;
			left: 50%;
			content: '';
			height: 2px;
			width: 20px;
			background: $themeColor; }
		&:before {
			transform: translate(-50%, -50%) rotate(45deg); }
		&:after {
			transform: translate(-50%, -50%) rotate(-45deg); } }
	&__large-image {
		width: 100%;
		padding-top: 66%;
		position: relative;
		background-color: #0E0F0E;
		@include sm {
			padding-top: 0;
			display: none;

			img {
				display: block;
				position: relative;
				top: 0;
				left: 0;
				transform: translate(0); } }
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 100%;
			max-height: 100%;
			transform: translate(-50%, -50%);
			object-fit: cover; } }
	&__images {
		display: flex;
		background-color: #0E0F0E;
		@include sm {
			flex-wrap: wrap; } }
	&__image {
		width: 20%;
		padding-top: 13.8%;
		position: relative;
		@include sm {
			width: 50%;
			padding-top: 33%; }
		@include xxs {
			width: 100%;
			padding-top: 66%; }

		img {
			@include coverdiv;
			object-fit: cover; } } }

.prop {
	display: flex;
	font-size: 12px;
	color: #888;
	@include sm {
		color: #aaaaaa; }
	& + & {
		margin-top: 12px; }
	&__option {
		text-transform: uppercase;
		min-width: 157px;
		width: 157px;
		margin-right: 70px;
		@include md {
			margin-right: 10px;
			min-width: 173px;
			width: 173px; } } }
@keyframes scroll {
	0% {
		transform: translateY(0); }
	100% {
		transform: translateY(-100%); } }

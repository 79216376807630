@mixin light {
  font-family: GothamPro, Arial, sans-serif;
  font-weight: 300; }

@mixin regular {
  font-family: GothamPro, Arial, sans-serif;
  font-weight: 400; }

@mixin medium {
  font-family: GothamPro, Arial, sans-serif;
  font-weight: 500; }

@mixin bold {
  font-family: GothamPro, Arial, sans-serif;
  font-weight: 700; }

@mixin black {
  font-family: GothamPro, Arial, sans-serif;
  font-weight: 900; }

.out {
  overflow: hidden;
  position: relative; }

//html
////removed becaus it delays navigation on page load
////scroll-behavior: smooth

body {
  background: $mainBg;
  font-size: 14px;
  line-height: 1.2;
  color: $white;
  @include regular;
  &.is-no-scroll {
    overflow: hidden; } }

a {
  color: $themeColor2;

  &:hover {
    text-decoration: none; } }

.text {
  letter-spacing: 0.7px;
  font-size: 22px;
  line-height: 1.7;
  text-align: center;

  font-size: 18px;
  @include tablet {
    font-size: 19px;
    letter-spacing: 1.1px;
    line-height: 1.8; }
  @include md {
    font-size: 16px;
    line-height: 1.8; }
  @include xs {
    font-size: 15px; }
  a {
    color: inherit; } }

.main {
  display: flex;
  flex-direction: column;
  &.blur {
    filter: blur(10px) grayscale(.6); } }

.title-gradient {
  letter-spacing: 0.01em;
  text-align: center;
  font-size: 92px;
  @include black;
  line-height: 1.07;
  text-transform: uppercase;
  background: -webkit-linear-gradient(120deg,$themeColor,$themeColorD);
  -webkit-background-clip: text;
  -webkit-text-stroke: 5px transparent;
  color: #161917;

  @include ie {
    color: #00dd3b;
    background: transparent;
    .leaf {
      color: #00dd3b;
      background: transparent; } }
  @include xxl {
    font-size: 5.5vw; }

  @include laptop {
    font-size: 65px;
    font-size: 5.7vw;

    font-size: 46px; }
  @include md {
    font-size: 60px;
    font-size: 6.4vw; }
  @include sm {
    font-size: 36px;
    font-size: 6vw;
    -webkit-text-stroke: 4px transparent; }
  @include xs {
    font-size: 34px; }

  em {
    position: relative;
    font-style: normal;
    background: linear-gradient(120deg,$themeColor,$themeColorD);
    -webkit-background-clip: text;
    color: #161917;
    -webkit-text-stroke: 5px transparent;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 684px;
      height: 700px;
      margin: -7px 0 0 -5px;
      background: url(../img/event-bg.svg?v2) center center no-repeat;
      z-index: -1; } }

  strong {
    position: relative;
    background: linear-gradient(120deg,$themeColor,$themeColorD);
    -webkit-background-clip: text;
    color: #161917;
    -webkit-text-stroke: 5px transparent;

    &:after {
      content: "";
      position: absolute;
      width: 69%;
      height: 50%;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-46%,-162%);
      transform: translate(-46%,-162%);
      background: url(../img/cup.svg) 50% no-repeat;
      background-size: 100% 100%; } } }

.wrapper-link {
  text-decoration: none;
  color: unset; }

.separator {
  height: 48px;
  background: #f00;
  margin: 0 0 -48px;
  background: url(../img/separator.svg); }

.pop-ticket__btn {
  &.btn {
    &.btn--border {
      color: $black; } } }

.s-banner__title {
  font-weight: 700 !important; }

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
  display: inline-block;
  stroke: currentColor;
  stroke-width: 0; }

@import "_variables.sass";

html {
	--color-brand: #{$themeColor};
	--color-brand-hover: #{$themeColorD};
	--color-popup-ticket-border: #{$themeColor}; }

.block {
	display: flex;
 }	// remove this

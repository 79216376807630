.header {
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  @include md {
    bottom: auto;
    top: 0;
    padding: 0; }

  &__logo {
    display: none; }

  &__nav {
    grid-column: 2;
    @include md {
      width: 100%;
      margin-bottom: 10px;
      margin: auto 0;
      padding-top: 20px;
      padding-bottom: 20px; } }
  &__btn {
    display: flex;
    justify-content: flex-end;
    @include md {
      order: 4; }
    .btn {
      width: 100%;
      height: 50px;
      border-radius: 0;
      padding: 0;

      width: 164px;
      @include laptop {
        width: 136px; }

      &:after,
      &:before {
        border-radius: 0; } } }

  &__alt-btn {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 214px;
    z-index: 2;
    pointer-events: auto;
    transition: opacity ease 0.1s;

    @include md {
      width: 160px;
      &.is-visible {
        display: block; } }

    .btn {
      width: 100%;
      height: 50px;
      border-radius: 0;
      padding: 0;

      &:after,
      &:before {
        border-radius: 0; } } }

  &__socials {

    display: flex;
    align-items: center;

    .social__link {
      display: block;
      svg {
        display: block; } }

    .social__item:not(:last-child) {
      @include laptop {
        margin-right: 10px; } }
    @include md {
      order: 3; } }

  &__inner {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    max-width: 1480px;
    padding: 0 20px;
    gap: 20px;
    align-items: center;
    min-height: 50px;
    margin: 0 auto;
    @include tablet {
      min-height: 46px; }
    @include md {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      height: 100svh;

      padding: 20px 0;
      overflow: hidden;
      overflow-y: auto;
      gap: 30px;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      z-index: 4;
      width: 100%;
      background-color: rgba(0,0,0,0.7);

      &.is-open {
        display: flex;
        padding-left: 0; } } }

  &__burger {
    display: none;
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 5;
    pointer-events: auto;
    @include md {
      top: 10px;
      display: block; } } }

.nav {
  display: flex;
  @include md {
    display: block; }
  &__link {
    text-decoration: none;
    text-transform: uppercase;
    color: $white;
    transition: 0.3s;
    font-size: 12px;
    height: 50px;
    display: flex;
    padding: 0 .7vw;
    align-items: center;
    justify-content: center;

    &:hover, &:focus-visible {

      color: #9dde00; }

    @include md {
      font-size: 14px;
      display: flex;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 500; }
    @include sm {
      font-size: 12px; }
    .no-touch &:hover {
      color: $themeColor; } } }

.burger {
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include sm {
    width: 50px;
    height: 50px; }

  span {
    transition: 0.3s;
    display: block;
    height: 4px;
    width: 36px;
    background-color: #ffffff;
    border-radius: 5px;
    &:not(:last-child) {
      margin-bottom: 6px; } }
  &.is-active {
    span {
      &:nth-child(1) {
        transform: translate(0, 10px) rotate(45deg); }
      &:nth-child(2) {
        transform: translateX(0);
        opacity: 0; }
      &:nth-child(3) {
        transform: translate(0, -10px) rotate(-45deg); } } } }

.s-discount-form {
  padding: clamp(50px, 5vw, 100px) 0;

  .container {

    @include xxs {
      padding: 0 10px; } } }



.discount-form {
  border: 5px solid $gray2;
  border-radius: 30px;
  padding: clamp(30px, 5vw, 80px) 20px  clamp(30px, 5vw, 60px);

  &__title {
    text-align: center;
    font-size: clamp(16px, 4vw, 24px);
    margin: 0 auto clamp(32px, 5vw, 52px);
    max-width: 500px;
    @include light;

    @include sm {
      @include regular; } }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 260px;
    margin: 0 auto; }

  &__field {

    &:not(:first-child) {
      margin: 10px 0 0;

      &.textarea {
        margin: 25px 0 0; } } }

  &__input,
  &__textarea {
    @include regular;
    width: 100%;
    min-height: 52px;
    line-height: 1.24;
    background: $white;
    border-radius: 26px;
    padding: 14px 20px;
    font-size: 14px;
    min-width: 100%;
    max-width: 100%;
    display: block; }

  &__textarea {
    min-height: 142px; }

  &__submit {
    height: 52px;
    margin: 40px auto 0;
    display: flex;
    min-width: 180px;

    @include sm {
      margin: 30px auto 0; } } }



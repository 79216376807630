.sponsors {
  padding: clamp(50px, 5vw, 100px) 0;

  .container {
    max-width: 1260px; }

  .section__title {
    @include sm {
      margin-bottom: 28px; } }
  &__description {
    max-width: 960px;
    margin: 0 auto 62px;
    @include r(680) {
      margin-bottom: 42px; }
    //+r(480)
    //margin-bottom: 30px
    p {
      font-size: 16px;
      line-height: 1.6;
      text-align: center;
      @include r(1480) {
        font-size: 18px; }
      @include sm {
        line-height: 1.8; }
      @include r(680) {
        font-size: 16px; }
      &:not(:last-child) {
        margin-bottom: 18px;
        @include sm {
          margin-bottom: 5px; } }
      a {
        color: $themeColor2;
        text-transform: uppercase; } } } }

.sponsors-block {
  padding-bottom: 50px;

  @include sm {
    padding-bottom: 20px; }

  & + & {
    margin-top: 50px;

    @include sm {
      margin-top: 30px; } }

  &:not(:last-child) {
    border-bottom: 3px solid $gray2; }

  &__title {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #888;
    margin-bottom: 20px; }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include sm {
      margin: 0 -10px; } }

  &__item {
    text-align: center;
    width: 100%;

    @include tablet {
      display: flex;
      justify-content: center; }

    @include sm {
      max-width: 230px; }

    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;
      padding: 20px;
      @include sm {
        padding: 10px; }

      &:hover {
        opacity: 0.7; }

      picture {
        display: block; }

      img {
        display: block;
        max-width: 100%;
        transition: all ease 0.2s;
        transform: translateY(0);
        opacity: 1;
        height: auto; } }

    &--w-info {
      width: 100% !important;
      max-width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1; } }
  &__content {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    line-height: 1.45;
    @include sm {
      font-size: 10px; }
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } }
    p:not(:last-child) {
      margin-bottom: 10px; } }
  &__subtitle {
    font-weight: 600;
    margin-bottom: 5px; }
  &.Platinum {
    .sponsors-block__item {
      width: 50%;
      max-width: 50%;
      @include sm {
        width: 100%;
        max-width: 100%; } } }

  &.Gold {
    .sponsors-block__item {
      width: 33.33%;
      max-width: 33.3%;
      @include xs {
        width: 50%;
        max-width: 50%; } } }

  &.Silver {
    .sponsors-block__item {
      width: 25%;
      max-width: 25%;

      @include xs {
        width: 33.3%;
        max-width: 33.3%; } } }

  &.MediaPartner,
  &.Partner,
  &.TechPartner,
  &.EntertainmentPartner {

    .sponsors-block__item {
      width: 20%;
      max-width: 20%;

      @include xs {
        width: 25%;
        max-width: 25%; } } }

  &_auto {
    .sponsors-block__list {
      justify-content: space-between; }

    .sponsors-block__item {
      width: auto;

      @include sm {
        width: 25%; }
      @include xs {
        width: 33.3%; }

      img {
        max-width: 100%; }

      &:last-child {
        margin: 0 auto 0 0; } } } }

.s-separator-text {
  border-bottom: 2px solid $gray2;
  padding: 20px 0 clamp(50px, 9vw, 150px); }

.separator-text {

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 0.9;
    font-size: clamp(44px, 6vw, 94px);

    strong {
      color: $themeColor2;
      font-weight: 900; } } }

.schedule {
	padding: 135px 0 0;
	margin: 0 0 80px;

	&+.schedule {
		padding: 0px 0 0;

		.section__title {
 } }			// display: none

	@include laptop {
		padding: 70px 0 0; }
	@include sm {
		padding: 40px 0 0;
		margin: 0 0 50px; }

	.container {
		max-width: 1280px;
		@include xxs {
			padding: 0; } }

	&__tip {
		color: #888;
		margin: 0 0 10px;
		@include xxs {
			padding: 0 20px; } }

	&__item {
		border-top: 1px solid #202421;
		&:last-child {
			border-bottom: 1px solid #202421; } }
	&__tab-links {
		display: flex;
		margin-bottom: 49px;
		@include md {
			margin-bottom: 20px; } }
	&__tab-link {
		@include medium;
		font-size: 20px;
		height: 44px;
		display: flex;
		align-items: center;
		text-align: center;
		color: $white;
		text-decoration: none;
		position: relative;
		text-transform: uppercase;
		margin: 0 0 0 56px;
		cursor: pointer;
		transition: all ease 0.2s;
		@include first {
			margin: 0; }
		@include md {
			font-size: 20px;
			padding: 10px; }
		@include sm {
			font-size: 18px; }
		@include xs {
			font-size: 14px;
			padding: 10px 5px; }
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 0;
			transition: 0.3s;
			background-image: linear-gradient(-210deg, #99ff00 0%, #00dd3b 100%); }
		&.is-active {
			&:after {
				height: 2px;
				@include sm {
					height: 2px; } } }
		.no-touch &:hover {
			color: $themeColor; } }
	&__tabs {
		@include xs {
			margin: 0; } }
	&__tab {
		display: none;
		&.is-active {
			display: block; } }

	&__btn {
		height: 34px;
		padding: 5px 20px;
		width: 170px;
		font-size: 12px;
		margin: 15px 0 0;

		@include tablet {
			padding: 5px 20px; }


		&:hover {
			background: $themeColor;
			border-color: $themeColor; } } }

.s-item {
	background: #131513;
	border-left: 1px solid #202421;
	border-right: 1px solid #202421;
	&__row {
		display: flex;
		@include sm {
			flex-wrap: wrap; }
		& + & {
			margin-top: 20px; } }
	&__col {
		padding: 16px 28px;
		border-right: 1px solid #202421;
		@include last {
			border: none; }
		&_d-sm-none {
			@include sm {
				display: none; } }
		@include md {
			padding: 16px 18px; }
		&:nth-child(1) {
			text-align: center;
			width: 9.7%;
			@include sm {
				width: 80px;
				min-width: 80px;
				border: none;
 } }				// border-bottom: 1px solid #202421
		&:nth-child(2) {
			width: 26.3%;
			@include sm {
				//width: auto
				flex-grow: 1;
				padding-left: 0;
 } }				// border-bottom: 1px solid #202421
		&:nth-child(3) {
			width: 64%;
			@include sm {
				width: 100%;
				margin-top: 10px;
				margin: 0;
				padding-top: 0; } } }
	&__time {
		font-size: 14px;
		margin: 4px 0; }
	&__title {
		font-size: 18px;
		a {
			color: #fff; }
		@include medium;
		@include md {
			font-size: 18px; }
		@include sm {
			font-size: 16px;
			margin: 2px 0; }
		& + .s-item__text {
			margin-top: 8px; }
		& + .s-item__company {
			margin-top: 8px;
			@include sm {
				margin-top: 5px; } }
		&_sm {
			font-size: 16px;
			margin: 18px 0 0;

			@include md {
				font-size: 16px; }

			@include sm {
				font-size: 14px;
				margin: 12px 0 0; } }

		&:first-child {
			margin-top: 0; } }


	&__text {
		letter-spacing: 0.3px;
		color: #888;
		line-height: 1.7;
		@include sm {
			color: #aaaaaa; }
		img {
			float: left;
			width: 30%;
			margin-right: 24px; }
		a {
			color: #fff; } }
	&__company {
		font-size: 14px;
		color: #5f5f5f;
		@include sm {
			color: #aaaaaa; } } }

.talks-list {
	padding: 30px 0 0;
	.s-item {
		background: #131513;
		border-left: 1px solid #202421;
		border-right: 1px solid #202421;
		&__row {
			display: flex;
			border-bottom: 1px solid #202421;
			@include sm {
				flex-wrap: wrap; }
			& + & {
				margin-top: 20px; } }
		&__col {
			padding: 16px 28px;
			border-right: 1px solid #202421;
			@include last {
				border: none; }
			&_d-sm-none {
				@include sm {
					display: none; } }
			@include md {
				padding: 16px 18px; }
			&:nth-child(1) {
				text-align: center;
				width: 30%;
				@include sm {
					text-align: left;
					width: 100%;
					border: none;
					padding: 16px; } }
			&:nth-child(2) {
				width: 70%;
				@include sm {
					width: 100%;
					margin-top: 10px;
					margin: 0;
					padding: 16px;
					padding-top: 0; } } } } }


.talks {
	padding: 149px 0 97px;
	border-bottom: 1px solid #2c302d;
	@include laptop {
		padding: 55px 0 60px; }

	a {
	  color: #fff; }
	@include sm {
		border-bottom: none;
		padding-bottom: 20px; }
	.container {
		@include sm {
			padding: 0 10px; } }
	.s-item {
		background: transparent;
		border: none;
		max-width: 1200px;
		margin: 0 auto;
		@include first {
			margin: 0; }

		&__col {
			border: none;
			@include sm {
				padding: 0 20px;
				margin: 16px 0 0;
				@include first {
					margin: 0; } } }

		&__text {
			line-height: 1.6;
			margin-top: 10px;

			a {
				color: #fff; } }
		&__title {
			@include regular; } }

	.talk__item {
		margin-top: 6px;
		@include first {
			margin-top: 0; }
		@include sm {
			margin-top: 16px;
			@include first {
				margin-top: 0; } }
		@include xs {
			margin: 10px -10px;
			@include first {
				margin-top: 0; } } } }

 :root {
  --banner-font: GothamPro, Arial, sans-serif;
  --banner-accent-font: GothamPro, Arial, sans-serif;
  --banner-btn-size: 12px;
  --banner-radius: 16px;

  --accentColor: #83F35D;

  --price-increase-bg: #161917;

  --checkout-section-bg: #161917;
  --checkout-section-color: #fff;
  --checkout-card-border: 2px solid #363636;
  --checkout-card-bg: #161917;
  --checkout-card-text-color: #fff;
  --checkout-quantity-bg: #E22900;
  --checkout-quantity-hover-color: #fff;
  --checkout-quantity-border: 1px solid #363636;
  --checkout-quantity-button-bg: #E22900;
  --checkout-quantity-button-bg-hover: #000;
  --checkout-quantity-button-icon-color: #fff;
  --checkout-quantity-button-icon-color-hover: #fff;
  --checkout-form-ff: GothamPro,Arial,sans-serif;
  --checkout-form-border: 1px solid #363636;
  --checkout-form-color: #fff;
  --checkout-price-ff: GothamPro,Arial,sans-serif;
  --checkout-card-title-weight: 500;
  --checkout-button-weight: 500;
  --checkout-button-border: 1px solid #E22900;
  --checkout-button-border-hover: 1px solid #000;
  --checkout-actions-sticky: 55px;

  --price-section-bg: #161917;
  --price-color: #fff;
  --price-item-bg: #161917;
  --price-item-border: 2px solid #363636;
  --price-item-radius: 16px;
  --price-item-title-color: #fff;
  --price-item-title-weight: 500;

  --price-slider-btn-size: 54px;
  --price-slider-btn-border-color: #363636;
  --price-slider-btn-radius: 50%;
  --price-slider-btn-color: #00FF00;
  --price-slider-btn-bg: #161917;
  --price-slider-btn-bg-hover: #222222;

  --price-btn-color: #fff;
  --price-btn-border: 1px solid #3C3C3C;
  --price-btn-border-color-hover: #5C5C5C;

  --ticketsFormBg: transparent;
  --ticketsFormBorder: 2px solid #363636;
  --ticketsFormRadius: 16px;
  --ticketsFormInputBorder: 1px solid #363636;
  --ticketsFormInputRadius: 8px;

  --workshop-item-ff: GothamPro;
  --workshop-item-color: #fff;
  --workshop-item-bg: #363636;
  --workshop-item-type-bg: #fff;
  --workshop-item-border: #363636;
  --workshop-item-type-color: #000;
  --workshop-item-type-color-free: #000;
  --workshop-item-type-radius: 4px; }

$font-size: 16px;

/*----- COLORS -----*/

$mainBg: #161917;
$mainBg2: #eaeaec;
$black: #000;
$white: #fff;
$gray: #202421;
$gray2: #363636;
$gray3: #838383;
$grayD: #838383;
$grayL: #eaeaec;
$themeColor: #99FF00;
$themeColorD: #00DD3B;
$themeColor2: #0f0;
$accent: #b1ec00;

// end COLORS

/*----- SCHEDULE -----*/
$posTop: 0px;
$scheduleColorGray: #c5c5c5;
$scheduleColorGrayL: #eaeaec;
$scheduleOutline: #8D8D8D;
// end SCHEDULE

/*----- CHECKOUT -----*/
$checkoutSectionBg: #161917;
$cehckoutInputColor: #fff;
$cehckoutInputBg: #353535;
$cehckoutColorGray: #eaeaec;
$cehckoutBadgeBg: #fff;
$cehckoutBadgeColor: #000;
// end CHEcKoUT

/*----- SWITCH -----*/
$switchBG: #99FF00;
$switchColor: $black;
$switchHandler: $black;

// end SWITCH

.video {
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5); }

	&__content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0; }

	&__btn {
		font-size: 113px;
		background-color: transparent;
		border: none;
		-webkit-appearance: none;
		display: flex;
		overflow: hidden;
		width: 94px;
		transition: transform .1s ease;
		@include hover {
			transform: scale(1.1);
			.icon__main {}
			&:before {
				background-color: rgba(0, 0, 0, 0.5); } }
		svg {
			width: 100%;
			fill: $white;
			z-index: 1;
			.icon__main {
				position: relative; } }
		&:before {
			position: absolute;
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: background-color .3s ease;
			display: none; }
		.btn-triangle {
			transition: fill .3s ease; } } }

.location {
  padding: 82px 0 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @include sm {
    padding: 40px 0 0; }
  &>.container {
    position: relative;
    z-index: 2;
    max-width: 1060px;
    @include sm {
      padding: 0; } }
  &__logo {
    max-width: 268px;
    margin: 0 auto 78px;

    @include rh(568) {
      margin-bottom: 164px; }
    img {
      width: 100%; } }
  &__map {
    // padding: 250px 50px 50px
    padding: 50px 50px 50px;
    display: flex;
    // justify-content: flex-end
    justify-content: center;
    position: relative;

    @include tablet {
      max-width: 940px;
      margin: 0 auto;
      // padding: 200px 45px 45px
      padding: 45px; }
    @include md {
      // padding: 200px 25px 25px
      padding: 25px; }
    // +sm
    //   height: 400px

    .map {
      width: 100%;
      height: 100%;

      img {
        display: block;
        object-fit: none;
        width: 100%;
        display: none;
        height: 100%;
        object-position: center;

        @include tablet {
          object-position: 40% center; }

        @include md {
          object-position: 50% center; }

        @include xs {
          object-position: 35% center; } } } }

  &__title {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 17px;
    @include sm {
      margin-bottom: 15px;
      @include bold;
      font-size: 22px; }
    h3 {
      font-size: clamp(24px, 5vw, 34px);
      font-weight: 900;
      text-transform: uppercase; }

    p {
      font-weight: 400;
      font-size: clamp(12px, 3vw, 14px); } }

  &__address {
    letter-spacing: 0.4px;
    font-size: 20px;
    line-height: 1.5;
    @include sm {
      font-size: 16px; } }

  // &__info
  //   position: relative
  //   z-index: 1
  //   +xs
  //     padding: 35px 20px
  //     position: absolute
  //     top: 0
  //     left: 0
  //     width: 100%

  //   .theme-link
  //     color: $themeColor2

  &__info {
    position: relative;
    z-index: 1;
    background-color: rgba(#161917, 0.4);
    padding: 35px;
    backdrop-filter: blur(10px);
    @include xs {
      padding: 25px; }

    .theme-link {
      color: $themeColor2; } }

  &__top {
    position: relative;
    padding: 0 50px; }
  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  &__slide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.5); } }

  &__arrow {
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, -50%);
    @include xs {
      transform: translate(0, 0);
      top: 45%;
      font-size: 50px; }
    &_prev {
      left: 0; }
    &_next {
      right: 0; }
    svg {
      fill: $white; } }
  &__img {
    display: none;
    position: absolute;
    width: 160px;
    top: 24px;
    right: 24px;
    z-index: 1;
    @include md {
      display: none; }
    img {
      width: 100%; } } }

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9; }

.video-widget {
	position: relative;
	margin: 0 0 -60px;
	padding-top: 80px;
	z-index: 1;

	@include laptop {
		padding-top: 80px; }
	@include tablet {
		padding-top: 72px; }
	@include md {
		padding-top: 50px;
		margin: 0 0 -40px; }
	@include sm {
		padding-top: 40px; } }

.faq {
  padding: 0;
  margin: 0 auto 50px;
  list-style: none;
  max-width: 970px;
  font-weight: normal;
  color: $white;

  &__section-title {
    font-size: 28px;
    font-weight: 500;
    margin: 50px 0 30px; }

  &__list {
    margin: 20px auto 20px; }

  &__description {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    padding: 20px 0;
    margin: 30px 0 0;
    @include sm {
      font-size: 14px; }
    p:not(:last-child) {
      margin-bottom: 15px; }
    a {
      color: var(--accentColor); } }

  &.marged {
    margin-top: 30px; }

  &__arrow {
    display: none; }

  &__item {
    position: relative;
    margin: 0 0 -2px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 2px;
      width: 2px;
      background: #fff;
      transition: all ease .3s;
      opacity: 0.1; }

    &.open {
      .faq__question {
        color: #fff;
        &:after {
          border-color: #fff;
          transform: rotate(-135deg);
          margin-top: -5px; } }

      &:after {
        background: $themeColorD;
        opacity: 1; } } }

  &__question {
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    color: #bbb;
    padding: 25px 90px 25px 40px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: left;
    background: transparent;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 30px;
      margin-top: -8px;
      height: 12px;
      width: 12px;

      border-bottom: 2px solid #bbb;
      border-right: 2px solid #bbb;
      transform: rotate(45deg); } }

  &__ansver {
    font-size: 16px;
    line-height: 1.5;
    display: none;
    padding: 0 40px 25px;
    max-width: 840px;
    color: #bbb;
    a {
      color: var(--accentColor); }

    p {
      margin: 0 0 16px;

      &:last-child {
        margin: 0; } }
    ul, ol {
      line-height: 1.3;
      margin-bottom: 15px; }

    ol {
      list-style-type: decimal;
      padding-left: 20px; }
    ul {
      li {
        position: relative;
        padding-left: 1.2em;
        &:before {
          content: '';
          position: absolute;
          top: 0.5em;
          left: 0;
          height: 0.3em;
          width: 0.3em;
          border-radius: 50%;
          background-color: #bbb; }
        &:has(ul), &:has(ol) {
          &:before {
            display: none; } } } } }

  @include sm {

    &__section-title {
      margin: 40px 0 20px;
      font-size: 26px;
      font-weight: 400; }

    &__list {
      margin: 20px -15px; }
    &__arrow {
      top: 24px;
      right: 28px; }

    &__question {
      font-size: 18px;
      cursor: pointer;
      padding: 15px 50px 15px 20px;
      &:after {
        right: 20px; } }

    &__ansver {
      font-size: 14px;
      padding: 0 15px 15px; } }

  @include xs {

    &__section-title {
      font-size: 24px; }

    &__question {
      font-size: 18px; } } }

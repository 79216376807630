.s-party {
  padding: clamp(50px, 5vw, 100px) 0;

  .section__title {
    text-transform: uppercase;
    line-height: 0.9;
    font-weight: 900;
    font-size: clamp(32px, 8vw, 84px);
    margin-bottom: clamp(38px, 8vw, 100px); } }

.party {

  &__item {
    display: flex;
    justify-content: space-between;

    @include sm {
      flex-direction: column-reverse; }

    &:nth-child(even) {
      flex-direction: row-reverse;

      @include sm {
        flex-direction: column-reverse; } }

    &:not(:first-child) {
      margin-top: 100px;

      @include sm {
        margin-top: 60px; } }

    @include md {
      gap: 50px; }

    @include sm {
      gap: clamp(20px, 4vw, 30px); } }

  &__text {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 630px;
    min-width: 320px;
    padding: 0 clamp(40px, 7vw, 100px);
    justify-content: center;

    @include md {
      padding: 0; }

    @include sm {
      text-align: center;
      min-width: 0; } }


  &__images {
    flex: 1 1;
    max-width: 490px;
    min-width: 320px;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include sm {
      margin: 0 auto;
      max-width: 380px;
      min-width: 0;
      gap: 5px; } }

  &__img {

    &.single {
      grid-area: 1 / 1 / 3 / 3; }

    &.fill-height {
      grid-area: 1 / 1 / 3 / 2; }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 10px;

      @include sm {
        border-radius: 6px; } } }

  &__title {
    font-weight: 900;
    text-transform: uppercase;
    font-size: clamp(24px, 5vw, 46px);
    line-height: 0.96; }

  &__desc {
    font-size: clamp(14px, 2.6vw, 16px);
    line-height: 1.5;
    margin: 18px 0 0; } }

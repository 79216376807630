.s-watching-party {
  padding: clamp(80px, 12vw, 200px) 0 clamp(40px, 12vw, 200px);
  border-bottom: 1px solid $gray2;

  .container {
    max-width: 1160px; } }

.watching-party {

  &__content {
    display: flex;
    align-items: center;
    gap: 20px 40px;
    justify-content: center;

    @include md {
      gap: 30px; }

    @include sm {
      flex-direction: column; } }


  &__title {
    font-size: clamp(44px, 8vw, 94px);
    line-height: 0.9;
    text-transform: uppercase;

    @include sm {
      text-align: center; } }

  &__img {
    flex: 0 0 auto;
    width: 100%;
    max-width: clamp(320px, 32vw, 490px);

    img {
      display: block;
      width: 100%;
      border-radius: 16px; } }


  &__bottom {
    max-width: 700px;
    margin: clamp(20px, 6vw, 72px) auto 0;
    display: flex;
    gap: 20px 64px;
    align-items: center;

    @include sm {
      flex-direction: column; } }

  &__desc {
    font-size: clamp(14px, 3vw, 16px);
    line-height: 1.5; }

  &__btn {
    padding: 5px 30px;
    min-height: 52px;
    white-space: nowrap;

    &:after,
    &:before {
      display: none; } } }


.social {
	display: flex;
	align-items: center;
	&__item {
		&:not(:last-child) {
			margin-right: 17px; } }
	&__link {
		font-size: 17px;
		.icon {
			fill: $white;
			transition: 0.3s; }
		.no-touch & {
			&:hover {
				.icon {
					fill: $themeColor; } } } }
	&--theme {
		.social {
			&__item {
				&:not(:last-child) {
					margin-right: 28px;
					@include tablet {
						margin-right: 15px; } } }
			&__link {
				font-size: 20px;
				.icon {
					fill: $themeColor; }
				.no-touch & {
					&:hover {
						.icon {
							fill: $white; } } } } } } }


.s-contact-form {
	padding: clamp(50px, 5vw, 100px) 0;

	.container {} }

.contact-form {
	text-align: center;

	&__title {
		text-transform: uppercase;
		line-height: 0.9;
		font-weight: 900;
		font-size: clamp(42px, 10vw, 94px); }

	&__desc {
		font-size: 16px;
		max-width: 360px;
		margin: clamp(24px, 6vw, 54px) auto;
		line-height: 1.52; }

	&__form {
		margin: 0 auto;
		max-width: 578px;
		width: 100%; }

	&__field {
		position: relative; }

	&__input {
		height: 60px;
		width: 100%;
		border-radius: 30px;
		padding: 0 60px 0 30px;
		font-size: 22px;
		&:focus-visible {
			outline-color: $themeColor2 !important; }

		@include xs {
			height: 50px; }

		&::placeholder {
			color: $black; } }

	&__submit {
		position: absolute;
		top: 3px;
		right: 3px;
		bottom: 3px;
		height: 54px;
		width: 54px;
		border-radius: 50%;
		background: $black;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			display: none; }

		.icon {
			fill: $themeColor2;
			width: 16px;
			height: 15px; }

		&:focus-visible {
			background: $themeColor2;
			.icon {
				fill: $black; } }

		@include xs {
			height: 46px;
			width: 46px;
			top: 2px;
			right: 2px;
			bottom: 2px; } }

	.social {
		display: flex;
		gap: 7px;
		flex-wrap: wrap;
		margin: clamp(22px, 6vw, 52px) auto;
		justify-content: center;

		&__item {
			margin: 0; }

		&__link {
			width: clamp(56px, 10vw, 78px);
			height: clamp(56px, 10vw, 78px);
			border: 3px solid $gray2;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			transition: all ease 0.1s;
			background: $mainBg;

			&:hover, &:focus-visible {
				border-color: $themeColor2;
				outline: none !important;

				.icon {
					fill: $themeColor2; } }

			.icon {
				width: 22px;
				height: 22px;
				fill: $themeColor2; } } } }

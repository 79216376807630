.techs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  @include sm {
    gap: 20px 16px; }
  &__item {
    width: 80px;
    @include sm {
      width: 71px; } }
  &__link {
    text-decoration: none;
    color: #fff;
    transition: color .1s;

    &:hover {
      text-decoration: underline;
      color: $themeColor; } }

  &__img-box {
    height: 64px;
    width: 64px;
    flex: none;

    margin: 0 auto 16px;

    picture {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%; }
    img {
      max-height: 100%;
      max-width: 100%; } }

  &__title {
    font-size: 12px;
    text-align: center; } }

.s-speakers {
  padding: clamp(50px, 5vw, 100px) 0;

  .container {
    max-width: 1440px; } }

.speakers-list, .mc-list {
  display: grid;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  @include xs {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)); }
  @include xxs {
    gap: 20px 10px; }

  &__item {

    &_cfp, &_ask {
      padding: 34px 15px;
      border: 5px solid $gray2;
      @include sm {
        padding: 25px 10px;
        border-width: 4px; } } }

  .btn {
    border: 3px solid $gray2;
    background: $mainBg;
    margin: 16px 0 0;
    color: $themeColorD;
    padding: 0 15px;
    height: 40px;
    display: inline-flex;
    min-width: 150px;
    @include sm {
      min-width: 100px;
      font-size: 10px; }
    &:after,
    &:before {
      display: none; }

    &:hover {
      background: transparent;
      border-color: $themeColorD; } }

  a {
    color: $themeColor; } }

.speaker {
  color: $white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  a {
    color: $themeColor;
    text-decoration: none; }

  &__tag {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    top: 10px;
    background: $black;
    height: 26px;
    padding: 0 10px;
    border-radius: 10px;
    font-size: 12px; }

  &__photo {
    position: relative;
    padding-bottom: 100%;

    grid-row: 1;
    grid-column: 1;

    img, video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      transition: all ease 0.1s; }

    video {
      z-index: 1; }

    .activity-holder {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      // opacity: 0
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-weight: 900;
      font-size: 16px;

      cursor: pointer;
      .slider {
        opacity: 0;
        transition: all ease 0.1s; }
      &:focus-visible, &:hover {
        .slider {
          opacity: 1; }
        & ~ picture img {

          filter: brightness(0.4); } } } }

  &__head {
    img {
      margin-bottom: 20px;
      display: block;
      max-height: 50px; }
    &+p {
      margin: 40px 0 18px; } }

  &__name {
    font-size: clamp(18px, 2vw, 22px);
    font-weight: 900;
    line-height: 0.92;
    text-transform: uppercase;
    margin-top: 18px; }

  &__company {
    line-height: 1.52;
    font-size: 12px;
    margin: 8px 0;
    display: flex;
    gap: 4px 8px;
    align-items: center; }

  &__talk {
    line-height: 1.52;
    font-size: 12px;
    margin: 12px 0;

    span {
      color: $gray3; }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }

  &__desc {
    line-height: 1.52;
    font-size: 12px;
    margin: 12px 0;
    color: #bbb;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    p {
      display: inline; }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }

  &__socials {
    position: relative;
    align-self: end;
    z-index: 3;
    display: flex;
    gap: 5px;
    padding: 10px;
    grid-row: 1;
    grid-column: 1;
    @include sm {
      padding: 8px 5px;
      gap: 4px; } }

  &__social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: $mainBg;
    @include sm {
      width: 28px;
      height: 28px; }

    .icon {
      width: 16px;
      height: 16px;
      fill: $white;
      transition: all ease 0.1s;
      @include sm {
        height: 14px;
        width: 14px; } }

    &:hover, &:focus-visible {

      .icon {
        fill: $themeColor; } } } }

.hero {
  position: relative;
  min-height: 100svh;
  padding: 120px 0 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #1e2220;
  z-index: 1;
  pointer-events: none;

  &.hero_inner {
    min-height: 30svh;
    pointer-events: auto; }

  &:after {
    content: '';
    @include coverdiv;
    z-index: 1;
    background-color: #000;
    opacity: 0.35; }
  // &:before
  // 	content: ''
  // 	+coverdiv
  // 	z-index: 1
  // 	background-image: url(../img/hero-overlay.png)
  // 	background-position: center
  // 	background-repeat: no-repeat
  // 	background-size: cover

  @include md {
    padding: 90px 0 40px; }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: brightness(0.7);
    object-fit: cover;
    pointer-events: none;

    // +sm
    //   display: none

    @media (prefers-reduced-motion) {
      display: none; } }

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    background: url(../img/hero.jpg) center center no-repeat;
    background-size: cover;
    filter: brightness(0.7);
    pointer-events: none; }

  &__inner {
    max-width: 1600px;
    //margin: 0 auto
    padding: 0 40px;
    z-index: 2;
    pointer-events: none;
    @include r(1460) {
      padding: 0 40px; }
    @include r(1279) {
      padding: 0 40px; }
    @include sm {
      padding: 0 20px; }
    @include xs {
      padding: 0 15px; } }

  &__logo {
    width: 112px;
    margin: 0 auto 62px;

    @include tablet {
      margin: 0 auto 36px; }
    @include md {
      margin: 0 auto 30px; }
    @include sm {
      width: 80px; }
    @include xs {
      margin: 0 auto 26px;
      height: auto; }

    @media screen and (max-height: 768px) {
      margin: 0 auto 32px; } }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    pointer-events: auto;
    margin-bottom: clamp(20px, 4vw, 40px);

    &-label {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      flex: 1;
      &:first-child {
        text-align: right; }
      &:last-child {
        text-align: left; }
      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          text-decoration: underline; } } }
    &-handler {
      width: 58px;
      height: 32px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 30px;
      position: relative;
      flex: none;
      & > span {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        position: absolute;
        left: 18px;
        top: 3px;
        transition: all 0.3s linear; } }

    &._swipe {
      .hero__switch-handler {
        & > span {
          left: 3px; } } }
    &--reverse {
      .hero__switch-handler {
        & > span {
          left: 18px; } }
      &._swipe {
        .hero__switch-handler {
          & > span {
            left: 29px; } } } }

    @include md {
      gap: 10px;
      .hero__switch-label {
        font-size: 11px; } } }

  &__suptitle {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 48px;
    text-align: center;
    @include tablet {
      margin-bottom: 32px; }
    @include md {
      margin-bottom: 28px; }
    @include sm {
      margin: 0 0 15px; }

    @media screen and (max-height: 768px) and (min-width: 1200px) {
      margin-bottom: 24px; } }

  &__title {
    font-size: clamp(38px, 6vw, 88px);
    line-height: 1;
    text-align: center;
    margin-bottom: 38px;
    font-weight: 900;

    margin-left: auto;
    margin-right: auto;

    @media screen and (max-height: 768px) {
      margin-bottom: 20px; }

    @include tablet {
      margin-bottom: 20px;
      margin-left: -30px;
      margin-right: -30px; }
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin-bottom: 20px;
      margin-left: 0;
      margin-right: 0; }
    @include xs {
      margin: 0 auto 20px;
      max-width: calc(100% - 5px); }
    & > div {
      display: inline;
      position: relative;
      max-width: max-content; } }
  &__sticky {
    position: absolute;
    bottom: -10%;
    right: -15%;
    white-space: nowrap;
    text-transform: uppercase;
    color: $black;
    padding: 0.5em 1em;
    border-radius: 100px;
    background: linear-gradient(96deg, #9F0 14%, #00DD3B 87.81%);
    font-size: clamp(10px, 1.2vw, 16px);
    transform: rotate(-8.463deg);
    font-weight: 700;
    @include sm {
      bottom: -15%;
      right: -20%;
      transform: rotate(-4.463deg); } }
  &__btn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 20px;
    @include sm {
      gap: 10px 15px; }
    .btn {
      pointer-events: auto;
      @include xs {
        flex: 1;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        min-width: 130px; } } }

  &__partner {
    position: absolute;
    right: 50%;
    transform: translateX(660px);
    top: 10px;
    font-size: 12px;
    line-height: 1.4;
    pointer-events: auto;
    z-index: 2;

    @include laptop {
      transform: translateX(0);
      right: 20px;
      top: 10px; }

    @include md {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: static;
      margin: 20px auto 0; }
    @include sm {
      font-size: 10px; } }

  &__partners {
    margin-top: 10px;
    @include md {
      margin-top: 20px; } }

  &__partner-img {

    img {
      height: 50px;
      width: auto;
      @include sm {
        height: 40px; } } }

  &__timer {
    position: absolute;
    padding: 0 4vw;
    right: 0;
    left: 0;
    bottom: 1vw;
    font-size: 15vw;
    font-weight: 600;
    color: $themeColor;
    opacity: .2;
    line-height: 1;
    white-space: nowrap;

    .live {
      text-align: center; } } }

.h-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 720px;
  gap: 40px;
  margin: 0 auto 60px;
  padding: 0;

  @media screen and (max-height: 768px) {
    margin: 0 auto 30px; }

  @include sm {
    gap: 20px; }
  @include xs {
    gap: 12px; }

  &__item {
    display: flex;
    flex-direction: column;

    @include md {
      text-align: center; }

    @include sm {
      align-items: center; } }

  &__value {
    font-size: clamp(30px, 6vw, 50px);
    margin: 0 20px 0 0;
    @include regular;
    display: flex;
    align-items: center;
    @include md {
      margin: 0;
      justify-content: center; }
    @include sm {
      justify-content: flex-start; }
    @include xs {
      margin-bottom: 0;
      line-height: 1.1; }

    .icon-infinity {
      width: 71px;
      height: 60px;
      fill: #FFF; } }

  &__text {
    font-size: 14px;
    line-height: 1.4;
    padding: 5px 0;
    &-top {
      font-size: 20px;
      @include medium;
      text-transform: uppercase;
      @include tablet {
        font-size: 16px; }
      @include xs {
        font-size: 14px; } }
    @include xs {
      font-size: 10px; }
    @include tablet {
      padding: 9px 0; }
    @include md {
      padding: 0; } }
  &__icon {
    margin-top: 10px;
    margin-left: 8px;
    font-size: 30px;
    &_anim {
      animation: heart 2s infinite; }
    .icon {
      fill: $white; }
    @include sm {
      margin-top: 6px;
      .icon {
        width: 0.9em; } } } }

@keyframes heart {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

.logo {
  display: block;
  //height: 100%
  width: 100%;
  pointer-events: auto;
  img {
    //height: 100%
    width: 100%; } }

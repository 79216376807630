.s-multipass {
  position: relative;
  padding: clamp(50px, 5vw, 100px) 0;
  border-bottom: 3px solid $gray2;

  .container {
    position: relative;
    z-index: 1; } }

.multipass {

  &__head {
    display: flex;
    max-width: 1000px;
    margin: clamp(32px, 4vw, 78px) auto;

    @include sm {
      flex-direction: column;
      align-items: center;
      text-align: center; } }

  &__title {
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    font-size: clamp(44px, 8vw, 94px);
    @include black;
    line-height: 0.9;
    text-transform: uppercase;
    color: $white;

    @include sm {
      margin: 0 0 clamp(30px, 6vw, 80px); } }

  &__content {
    display: flex;
    margin: clamp(60px, 9vw, 128px) auto clamp(20px, 4vw, 70px);

    @include sm {
      flex-direction: column; } }

  &__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 400px;
    text-align: center;

    @include sm {
      flex: 1 1; } }

  &__card {
    @include sm {
      margin-bottom: 30px; } }

  &__tip {
    font-size: clamp(12px, 3vw, 16px); }

  &__equals {
    display: none; }

  &__btn {
    display: inline-flex;
    padding: 16px 30px;
    margin: 30px auto 0;

    &:after {
      background: linear-gradient(to right, #fef102, #fe9502); }

    &:before {
      background: linear-gradient(to right, #fe9502, #fef102); } } }

.visa-card {
  position: relative;
  width: 334px;
  height: 198px;

  @include md {
    width: 234px;
    height: 140px; }

  @include sm {
    margin: 0 auto; }

  &:before {
    content: '';
    position: absolute;
    width: 268px;
    height: 268px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%) rotate(66.58deg);
    background: conic-gradient(from 180deg at 50% 50%, #FE5D02 0deg, #FEC902 43.02deg, #FEF502 146.08deg, #FE5D02 360deg);
    z-index: -1;

    @include md {
      width: 188px;
      height: 188px; } }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    margin: 0;
    background: rgba(2, 254, 57, 0.2);
    z-index: -2;
    background: #02fe39;
    pointer-events: none;
    background: radial-gradient(circle, rgba(255, 230, 0, 0.12) 0%, rgba(0, 0, 0, 0) 65%); }

  &__card {
    position: relative;
    align-items: center;
    width: 334px;
    height: 198px;
    backdrop-filter: blur(20px);
    border-radius: 16px;
    background: linear-gradient(131.33deg, rgba(255, 255, 255, 0.4) 18.45%, rgba(255, 255, 255, 0.1) 73.67%);
    box-shadow: 0px 3.58195px 22.3872px -2.68646px rgba(0, 0, 0, 0.06);
    border: 1px solid transparent;
    border-color: #fef693 #8e8e81 #a9984b #b9a053;
    z-index: 1;
    display: flex;
    text-align: left;
    padding: 0 48px;

    @include md {
      width: 234px;
      height: 140px;
      padding: 0 28px; } }

  &__img {
    margin: 0 14px 0 0;

    .icon-face {
      width: 66px;
      height: 84px;
      fill: #fff;

      @include md {
        width: 46px;
        height: 58px; } } }

  &__desc {

    div:first-child {
      @include bold;
      font-size: 24px;
      text-transform: uppercase;
      line-height: 0.9;
      margin: 0 0 2px;

      @include md {
        font-size: 16px; } }

    div:last-child {
      font-size: 12px;
      margin: 5px 0 0;
      line-height: 0.9;

      @include md {
        font-size: 10px; } } }

  &__side {
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 25px 18px;
    transform: rotate(180deg);
    opacity: 0.4;
    writing-mode: vertical-lr;
    display: flex;
    justify-content: space-between;

    @include md {
      padding: 16px 10px;
      font-size: 10px; }

    .icon {
      width: 18px;
      height: 18px;
      fill: $white;

      @include md {
        width: 14px;
        height: 14px; } } } }

.multipass-list {
  width: 100%;
  margin: auto 0;
  padding: 3px 0;
  a {
    @include medium;
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: $themeColor;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease .2s;
    z-index: 1;
    &:hover {
      text-decoration: underline; } }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 100px;

    img {
      display: block;
      max-width: 94%;
      height: auto;
      margin: auto;
      opacity: 1;
      transition: all ease .2s; }

    a:hover, a:focus-visible {
      opacity: 1;
      & ~ picture img {
        opacity: 0.2; } }

    .icon-plus {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      opacity: 1;
      fill: #fff;
      transition: all ease .2s; }

    &.swiper-slide-active {
      .icon-plus {
        opacity: 0; } } } }

.section {

	&__title {
		text-align: center;
		font-weight: 500;
		font-size: 24px;
		margin-bottom: 63px;
		letter-spacing: 0.6px;

		.green {
			color: $themeColor; }

		@include tablet {
			margin-bottom: 37px;
			font-size: 23px; }

		&_big {
			font-size: 32px;
			font-weight: 700; } }

	&__subtitle {
		font-weight: 400;
		text-align: center;
		font-size: 16px;
		line-height: 1.6;
		max-width: 780px;
		margin: 30px auto 40px;

		@include tablet {
			font-size: 16px;
			margin: 30px auto 30px; } }

	&__info {
		text-align: center;
		font-size: 16px;
		line-height: 1.6;
		max-width: 880px;
		margin: 30px auto 26px;

		@include tablet {
			font-size: 16px;
			margin: 20px auto 16px; }

		p {
			&:not(:first-child) {
				margin: 24px 0 0; } }

		a {
			color: $themeColor2;
			text-transform: uppercase;
			font-weight: 700; } }

	&__text {
		max-width: 950px;
		margin: 0 auto;
		@include tablet {
			max-width: 870px; } } }

.s-features-grid {
  // padding: clamp(40px, 4vw, 80px) 0 0

  .container {
    max-width: 1920px;
    padding: 0;
    height: 100%;
    width: 100%;
    position: relative; } }

.features-grid {
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .3s ease;
    .icon {
      fill: $white;
      font-size: 68px; } }

  &__item {
    margin: 0 0 -1px;
    height: calc((100vw / 4) - 30px);
    display: block;
    position: relative;
    text-decoration: none;

    @include tablet {
      min-height: calc((100vw / 3.6) - 10px); }
    @include sm {
      min-height: calc(100vw / 1.65); }

    .video {
      position: absolute;
      width: 100%;
      height: 100%; }

    &.quarter {
      width: 25%;
      @include sm {
        width: 50%; }

      @include xs {
        width: 100%; } }

    &.third {
      width: 33.33%;

      @include sm {
        width: 50%; }

      @include xs {
        width: 100%; } }

    &.half {
      width: 50%;

      @include sm {
        width: 50%; }

      @include xs {
        width: 100%; } }

    &.full {
      width: 100%; }

    &.no-link {
      @include hover {
        .features-grid__content {
          opacity: 1; }
        .features-grid__pop {
          opacity: 1; } } } }

  &__fb {
    display: block;
    width: 94px;
    height: 94px;
    background: url(../img/facebook-circle.svg) center center no-repeat; }

  &__img {
    @include vcenter;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity .3s ease;
    &_hover {
      opacity: 0; } }

  &__pop {
    color: $white;
    font-size: 1.3vw;
    line-height: 1.5;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 55px 60px;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity .3s ease;
    opacity: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

    @include md {
      font-size: 1.6vw;
      padding: 20px 20px; }

    @include sm {
      font-size: 3vw;
      padding: 20px 20px; } }

  &__title {
    font-weight: 900;
    font-size: clamp(24px, 3vw, 46px);
    line-height: 1;
    text-transform: uppercase; }

  &__description {
    font-size: clamp(12px, 1vw, 18px);
    line-height: 1.32;
    margin: 20px 0 0;
    max-width: 400px; }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover; } }

.btn {
  display: inline-flex;
  cursor: pointer;
  text-align: center;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  @include bold;
  color: $black;
  text-decoration: none;
  transition: all ease 0.3s;
  transform: translateZ(0) perspective(1px);
  backface-visibility: hidden;

  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-backface-visibility: hidden;
  border: 2px solid transparent;
  border-radius: 30px;

  &:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    bottom: -2px;
    right: -2px;
    z-index: -2;
    background: linear-gradient(145deg, $themeColor, $themeColorD);
    transition: all ease 0.1;
    border-radius: 26px; }

  &:before {
    transition: 0.2s;
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    bottom: -2px;
    right: -2px;
    z-index: -1;
    background: linear-gradient(145deg, $themeColorD, $themeColor);
    transition: all ease 0.1;
    border-radius: 26px;
    opacity: 0; }

  &:hover, &:focus-visible {

    background-color: $themeColorD;

    &:before {
      opacity: 1; } }

  &:focus-visible {
    box-shadow: 0 0 12px 3px rgba(#aaa, 0.4); }

  @include tablet {
    padding: 0 20px; }

  &--gradient {
    border: 2px solid transparent;
    color: #000;
    font-size: 12px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -2;
      background: linear-gradient(145deg, $themeColor, $themeColorD);
      transition: all ease 0.1; }

    &:before {
      transition: 0.2s;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background: linear-gradient(145deg, $themeColorD, $themeColor);
      transition: all ease 0.1; }

    &:hover {
      &:before {
        opacity: 0; } }

    @include tablet {
      padding: 0 20px; } }

  &--transparent {
    border-color: $gray2;
    background: transparent;
    color: $themeColor2;

    &:after {}
    &:before {
      display: none; }

    &:hover, &:focus-visible {
      background: transparent;
      border: 2px solid $themeColor2; } }

  &--border, &--secondary {
    border: 2px solid $themeColor2;
    color: $themeColor2;
    font-size: 12px;
    position: relative;
    background: transparent;

    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -2;
      border-radius: 25px;
      background: linear-gradient(to right, $themeColor, $themeColorD);
      animation: AnimationName 5s linear infinite;
      display: none; }

    &:before {
      opacity: 0;
      transition: 0.2s;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      border-radius: 25px;
      background: linear-gradient(to right, $themeColor, $themeColorD);
      animation: AnimationName 5s linear infinite;
      display: none; }

    &:hover, &:focus-visible {
      color: #000;
      border-color: transparent;

      &:after {
        opacity: 1; } }

    @include tablet {
      padding: 0 23px; } }

  &--huge {
    width: 100%;
    margin: 0 auto;
    padding: 20px 6vw;
    height: auto;
    font-weight: 900;
    line-height: 0.9;
    border: 3px solid $themeColor2;
    color: $white;
    font-size: clamp(22px, 5vw, 72px);
    background: transparent;
    height: clamp(56px, 10vw, 152px);
    align-items: center;
    justify-content: center;
    border-radius: clamp(28px, 10vw, 76px);
    margin: clamp(40px, 4vw, 80px) auto;

    &:after,
    &:before {
      display: none; }

    @include tablet {
      padding: 15px 8vw; }

    .icon {
      fill: $white;
      width: clamp(35px, 8vw, 113px);
      height: 60px;
      margin-left: clamp(30px, 5vw, 96px); } } }

.icon-link {
  color: #fff;
  .icon {
    font-size: 18px;
    fill: $white; } }

.theme-link {
  font-size: 14px;
  color: $themeColor;
  text-transform: uppercase;
  text-decoration: none;
  &:hover, &:focus-visible {
    text-decoration: underline; } }

.is-hidden {
  display: none; }
